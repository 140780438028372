.App {
  font-family: "Poppins", sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sc-bdVaJa::after,
.dKGVIR::after {
  color: #5d9edb !important;
  width: 24px !important;
  height: 24px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.swal2-popup .actionContainer button {
  border-radius: 12px;
}

.custom-btn-toolbar {
  display: inline !important;
}

.custom-btn-toolbar > .btn {
  margin-right: 1rem;
}
.cursor-pointer {
  cursor: pointer;
}

.link {
  color: #4090db;
  text-decoration: underline;
}

.success-badge,
.wait-badge {
  font-size: 12px;
  padding: 5.5px 11px;
  background: rgba(255, 195, 30, 0.1725490196);
  width: fit-content;
  color: rgb(42, 42, 42);
  border-radius: 10px;
  &.wait-badge {
    background: rgba(8, 170, 112, 0.2117647059);
  }
}

input,
input:hover,
input:focus,
textarea,
textarea:hover,
textarea:focus {
  box-shadow: none !important;
  outline: none !important;
}

.fqTable {
  margin-left: auto;
  margin-right: auto;
}
.app-container {
  padding: 3em !important;

  width: 100% !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
