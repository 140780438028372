.sub-text-container {
  color: rgb(0, 0, 0);
  padding: 0;
  font-size: 15px;

  &.inline {
    display: inline;
  }
  span {
    .prominent {
      font-size: 12px;
      color: black;
      font-weight: 400;
    }
    &.small {
      font-size: 12px !important;
      line-height:20px;
      
    }
    &.underline {
      text-decoration: underline;
    }
    &.large {
      font-size: 20px !important;
    }
    &.colored {
      color: #4090db;
    }
    &.red {
      color: #ff5c00;
    }
    &.normal-gray {
      color: #797a7a;
      font-size: 14px !important;
      
      font-weight: 500;
    }
    &.semi-bold {
      font-weight: 500;
    }
    &.bold {
      font-weight: 600;
    }
    &.x-small {
      font-weight: 500;
      font-size: 12px !important;
      .prominent {
        font-size: 14px;
      }
    }
    &.mid {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    &.blue {
      cursor: pointer;
      line-height: 2.3;
      font-size: 12px;
      font-weight: 500;
      color: #0c5eca;
      white-space: nowrap;
      &:hover{
        text-decoration: underline;
      }
    }
    &.gray {
      color: #828181;
      font-weight: 500;
      margin-top: 4px;
      font-size: 11px;
    }
    &.disbaled {
      opacity: 0.5;
    }
  }
}
