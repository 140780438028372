.sidebar-container {
  width: 20vw;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 4;
  position: sticky;
  height: calc(100vh - 70px);
  .sidebar-sticky {
    border-right: 2px solid rgba(163, 167, 172, 0.1);
    left: 0;
    padding: 3em 1em;
    position: fixed;
    top: 70px;
    height: calc(100vh - 70px);
    width: 20vw;
    max-width: 300px;
    box-shadow: none !important;

    .add-organization-btn {
      padding: 0;
    }
    .all-organizations {
      margin-top: 3em;
    }
    .org-sidebar-parent {
      .sub-text-container {
        white-space: nowrap;
      }
      .org-name {
        margin: 30px 0 15px 0;
      }
    }
    .settings-sidebar-parent * {
      img {
        object-fit: cover;
        margin-top: -5px;
        height: 15px;
      }
    }
  }
  img {
    height: 18px !important;
    margin-bottom: -5px;
    margin-right: 1em;
  }
  .current {
    border-radius: 14px;
    box-shadow: rgba(228, 237, 247, 0.2) 0px 8px 24px;
    background-color: #d8e3fa;
  }
}
