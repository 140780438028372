.navbar-container {
  position: fixed;
  // position: sticky;
  width: 100%;
  top: 0;
  z-index: 10;
  padding: 0 3%;
  background-color: white;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  .navbar {
    padding: 0;
    display: flex;
    justify-content: space-between;
    height: 70px;
    .navbar-brand {
      display: flex;
      padding-right: 3em;
      cursor: pointer;
      img {
        object-fit: cover;
        &.logo {
          height: 31px;
        }
      }
    }
    .user-image {
      height: 15px;
    }
  }
  .nav-link {
    padding: 0 3vw;
  }
  @media screen and (max-width: 765px) {
    .nav-link {
      display: none;
    }
  }
}
