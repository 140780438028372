.button-primary-container {
  width: 100%;
  position: relative;
  .Primary-button {
    position: relative;
    background-color: #0c5eca;
    border-radius: 12px;
    border: 0;
    font-weight: bold;
    min-width: fit-content;
    max-width: 100%;
    height: 45px;
    white-space: nowrap;
    padding: 5px 22px;
    color: white;
    text-transform: capitalize;
    &.mid {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-size: 12px;
      padding: 5px 10px;
      width: calc(100% + 30px);
    }
    &:disabled {
      background-color: #4090dbaf;
    }
    &.copy-link {
      padding: 0 20px 0 15px;
      border-radius: 12px;

      &:after {
        content: "";

        background: #5d9edb;
        display: block;
        position: absolute;
        padding-top: 42%;
        padding-left: 100%;
        margin-left: -12px !important;
        margin-top: -28%;
        opacity: 0;
        border-radius: 16px;
        transition: all 0.8s;
      }

      &:active:after {
        padding: 0;
        margin: 0;
        opacity: 1;
        transition: 0s;
      }
    }
  }

  .Secondary-button {
    position: relative;
    background-color: white;
    border-radius: 12px;
    border: 0;
    font-weight: bold;
    min-width: fit-content;
    max-width: 100%;
    height: 45px;
    white-space: nowrap;
    padding: 5px 22px;
    color: #334054;
    border: 1px solid #d0d5dd;
    text-transform: capitalize;
    &.mid {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-size: 12px;
      padding: 5px 10px;
      width: calc(100% + 30px);
    }
    &:disabled {
      background-color: #4090dbaf;
    }
    &.copy-link {
      padding: 0 20px 0 15px;
      border-radius: 12px;

      &:after {
        content: "";

        background: #5d9edb;
        display: block;
        position: absolute;
        padding-top: 42%;
        padding-left: 100%;
        margin-left: -12px !important;
        margin-top: -28%;
        opacity: 0;
        border-radius: 16px;
        transition: all 0.8s;
      }

      &:active:after {
        padding: 0;
        margin: 0;
        opacity: 1;
        transition: 0s;
      }
    }
  }
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
