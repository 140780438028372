.navbar-dropdown-container {
  margin-left: 30px;
  cursor: pointer;
  position: relative;

  .dropdown {
    position: absolute;
    right: 0;
    top: 55px;
    width: 250px;
    z-index: 10;
    text-align: left;
    height: fit-content;
    padding: 20px 0;
    background-color: white;
    line-height: 35px;
    border-radius: 30px;
    box-shadow: 0 1px 6px #797e8d47;
    border-color: rgba(223, 225, 229, 0);
    span {
      display: flex;
      align-items: center;
      margin: 0 !important;
      width: 100%;
      padding: 8px 25px;
      i {
        font-size: 14px;
        margin-right: 10px;
      }
      &:hover {
        background-color: #f5faff;
      }
    }
  }
}
