.heading-container {
  * {
    color: rgb(0, 0, 0);
    font-weight: bold;
    margin-bottom: 0;
  }
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 22px;
    font-weight: 700;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 13px;
  }
  .color-primary {
    color: #4090db;
  }
}
