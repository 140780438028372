.help-container {
  .btn-support {
    position: fixed;
    z-index: 4;
    bottom: 10%;
    right: 50px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    // Ensure ButtonPrimary displays correctly
    button {
      font-size: 16px;
      padding: 10px 20px;
    }
  }

  .help-modal {
    position: fixed;
    bottom: 19%;
    right: 50px;
    animation: pop-swirl 1s ease forwards; /*trigger the keyframe*/
    min-width: 350px;
    border-radius: 14px;
    z-index: 4;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    min-height: 340px;
    background-color: white;
    .help-modal-header {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #344054;
      padding: 10px 0;
      background: #e8effd;
      border-radius: 14px 14px 0px 0px;
    }
    .help-modal-body {
      padding: 20px 20px;
      .email-section {
        font-size: 14px;
        display: flex;
        gap: 6px;
        align-items: center;
        .email {
          color: #667085;
        }
        span {
          font-size: 14px;
          font-weight: 400;
        }
        .input-field-container {
          flex-grow: 1;
        }
      }
      input,
      textarea {
        margin: 16px 0;
      }
      textarea {
        height: 150px;
      }
      .upload-section {
        display: flex;

        flex-direction: column;
        gap: 8px;
        padding-bottom: 16px;
        .upload-file-btn {
          width: fit-content;
          padding-inline: 10px;
          padding-top: 8px;
          padding-bottom: 8px;
          color: #344054;
          font-size: 14px;
          border: 1px solid #d0d5dd;
          background-color: #fcfcfd;
          border-radius: 8px;

          .upload-btn-inner {
            display: flex;
            gap: 8px;
          }
        }
        .size-text {
          font-size: 14px;
          color: #667085;
          font-weight: 400;
        }

        .error-text {
          color: red;
          font-size: 14px;
        }
        .file-details {
          display: flex;
          gap: 10px;
          font-size: 14px;
          justify-content: space-between;
          padding: 10px;
          color: #344054;
          border: 1px solid #d0d5dd;
          background-color: #fcfcfd;
          border-radius: 8px;
          align-items: center;
          .file-name-section {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }
      .input-field-container {
        .text-area-wrapper {
          textarea {
            resize: none;
            padding: 10px;
          }
        }
      }
    }
  }
  @keyframes pop-swirl {
    0% {
      transform: scale(0) rotate(360deg);
    }
    60% {
      transform: scale(0.8) rotate(-10deg);
    }
    100% {
      transform: scale(1) rotate(0deg);
    }
  }
}
