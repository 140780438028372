.input-field-container {
  max-width: 100%;
  position: relative;

  .text-area-wrapper {
    position: relative;
    .error {
      border: 1px solid red;
    }

    .success {
      border: 1px solid green;
    }

    textarea {
      width: 100%;
      padding: 10px;
      padding-right: 40px; /* Ensure space for the microphone icon */
      font-size: 14px;
      min-height: 100px; /* Adjust height as needed */
      border: 1px solid #ccc;
      border-radius: 5px;
      resize: vertical; /* Allow vertical resizing */
      box-sizing: border-box; /* Ensure padding doesn't affect width */
    }
  }

  input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Ensure padding doesn't affect width */
  }

  .microphone {
    position: absolute;
    right: 25px;
    top: 12%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  i {
    position: absolute;
    right: 0;
    top: 35px;
  }

  @media screen and (max-width: 768px) {
    input,
    textarea {
      font-size: 16px;
    }
  }
}
