.small-loader-component {
  border: 2px solid white;
  border-radius: 50%;
  border-top: 2px solid #bdbdbd;
  width: 20px;
  height: 20px;
  margin-right: 10px !important;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
