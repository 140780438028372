.nav-item-container {

  .current{
    color:#0c5eca !important;
    font-weight: 700 !important;
  }
  .nav-link {
    text-transform: uppercase;
    color: black;
    height: 60px;
    display: flex;
    font-size: 12px;
    display: flex;
    white-space: nowrap;
    align-items: center; 
    text-align: center;
    font-weight: 500;
    width: 100%;
 
    margin: 0;
 
    i {
      font-size:15px;
      margin-top:-2px;
      font-weight: 500;
      margin-right:10px;
    }
    img {
      height: 15px !important;
      margin-right:10px;
    }
  }
}
