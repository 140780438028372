.button-floating-container {
  width: fit-content;
  .button-floating {
    background: rgb(255, 255, 255);
    width: 70px;
    border-radius: 50%;
    border: 0;
    font-size: 25px;
    color: #0c5eca;
    font-weight: bold;
    position: fixed;
    z-index: 4;
    bottom: 10%;
    right: 50px;
    height: 70px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    img {
      width: 37px;
      animation: fade2 1s linear;
      &.cross-img {
        width: 27px;
        animation: fade1 1s linear;
      }
    }
    @keyframes fade1 {
      20% {
        transform: rotate(45deg);
        width: 40px;
        right: 52px;
        top: -24px;
      }
      40% {
        transform: rotate(45deg);
        width: 40px;
        left: 52px;
        top: -24px;
      }
      60% {
        transform: rotate(0deg);
        width: 50px;
        left: 0;
        top: -42px;
      }
    }

    @keyframes fade2 {
      20% {
        transform: rotate(-45deg);
        width: 40px;
        left: 52px;
        top: -24px;
      }
      40% {
        transform: rotate(-45deg);
        width: 40px;
        left: 52px;
        top: -24px;
      }
      60% {
        transform: rotate(0deg);
        width: 60px;
        left: 0;
        top: -42px;
      }
    }
  }
}
